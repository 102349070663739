import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="login"
export default class extends Controller {
  static targets = [ 'loginForm' ]

  connect () {
    console.log('login controller connect')
  }

  close (evt) {
    this.loginFormTarget.classList.add('hidden')
  }
}
