<template>
  <page-title></page-title>
  <page-description></page-description>

  <section class="mt-10">
    <div
      class="grid grid-cols-5 gap-2 *:border-2 *:h-16 *:flex *:justify-center *:items-center"
    >
      <div
        v-for="ch in preconfigured_queries"
        :key="ch"
        class="hover:bg-stone-400 hover:text-white hover:cursor-pointer"
      >
        {{ ch }}
      </div>
    </div>
  </section>

  <section class="mt-10">
    <h2>Daten und Auswertung</h2>
    <div class="flex flex-wrap gap-4">
      <div class="flex gap-2 items-center">
        <label class="whitespace-nowrap">Datum von / bis</label>
        <input type="date" name="" id="" class="!w-36" />
        <input type="date" name="" id="" class="!w-36" />
      </div>
      <div class="flex gap-2 items-center">
        <label for="">Perioden</label>
        <div class="flex gap-2">
          <button class="p-2 border-2 rounded">Jahr</button>
          <button class="p-2 border-2 rounded">Monat</button>
          <button class="p-2 border-2 rounded">Woche</button>
          <button class="p-2 border-2 rounded">Tag</button>
        </div>
      </div>
    </div>

    <div class="mt-10">
      <CanvasJSChart :options="data"></CanvasJSChart>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue"

import PageTitle from "./components/page-title.vue"
import PageDescription from "./components/page-description.vue"

const preconfigured_queries = ref([
  "Verletzungen",
  "Versicherungszweig",
  "Unfallart",
  "Meldungsart",
  "Tätigkeit",
  "Hergang",
  "Lohnart",
  "Verletzungen",
  "Unfallort",
  "Ursache",
])

const data = ref({
  animationEnabled: true,
  title: {
    xtext: "Vue.js Basic Column Chart",
  },
  data: [
    {
      type: "column",
      dataPoints: [
        { label: "apple", y: 10 },
        { label: "orange", y: 15 },
        { label: "banana", y: 25 },
        { label: "mango", y: 30 },
        { label: "grape", y: 28 },
      ],
    },
  ],
})
</script>
