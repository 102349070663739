import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-employer"
export default class extends Controller {
  static outlets = [ 'issue-form' ]

  connect () {
    console.log('select-employer connect')
  }

  change (evt) {
    console.log('select-employer#change', evt, evt.target)
    const employer_id = evt.params.id
    const url = evt.params.url
    const random_id = evt.params.randomId

    if (random_id) evt.preventDefault()
    if (!random_id) return

    console.log('issueFormOutlet', this.issueFormOutlet)
    console.log('url', url, 'random_id', random_id, 'id', employer_id, document.querySelector(`#issue-edit-${ random_id }-content #issue_employer_id`).value)

    document.querySelector(`#issue-edit-${ random_id }-content #issue_employer_id`).value = employer_id
  }
}
