import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="codefield"
export default class extends Controller {
  static targets = [ 'targetField' ]

  copy (evt) {
    this.targetFieldTarget.value = evt.target.innerText
  }
}
