import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

// Connects to data-controller="select"
export default class extends Controller {
  static values = {
    url: String
  }

  connect () {
    console.log('select connected', this.urlValue)
    console.log('data', this.element)

    new SlimSelect({
      select: this.element,
      events: {
        afterChange: (newValue) => {
          console.log('new value:', newValue[ 0 ])
          this.element.dataset.value = newValue[ 0 ].value
        },
        search: (search, current) => {
          return new Promise((resolve, reject) => {
            console.log(search, current)
            let url = this.urlValue
            if (url.match(/\?/)) {
              url = url + '&'
            } else {
              url = url + '?'
            }
            fetch(`${ url }search=${ search }`, {
              method: 'get',
              headers: {
                'Content-type': 'application/json'
              }
            })
              .then((response) => response.json())
              .then((data) => {
                console.log(data)
                resolve(data)
              })

          })
        }
      }
    })
  }
}
