import consumer from "./consumer"

export class Issue {

  constructor (issue_id) {
    return consumer.subscriptions.create({
      channel: 'IssueChannel',
      issue_id: issue_id
    }, {
      connected () {
        console.log('>>>>>> issue_channel connected', issue_id)
      },

      disconnected () {
        console.log('issue_channel disconnected', issue_id)
      },

      received (response) {
        console.log('issue_channel got response:', response)
      },

      send_changed_data (data) {
        console.log('issue_channel#send_changed_data', data)
        this.perform('changed_data', data)
      },
    })
  }
}

