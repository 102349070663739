import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dialog"
export default class extends Controller {
  static values = {
    url: String
  }
  connect () { }
  open (evt) {
    evt.preventDefault()

    console.log('*** dialog controller open')
    this.element.show()
  }

  openModal (evt) {
    evt.preventDefault()

    console.log('*** dialog controller openModal')
    this.element.showModal()
  }

  close (evt) {
    console.log('dialog controller close')
    this.element.close()
  }
}
