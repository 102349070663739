import { Controller } from "@hotwired/stimulus"
import InputMask from 'inputmask'

// Connects to data-controller="field-sv-number"
export default class extends Controller {
  static targets = ['input']

  initialize() {
    InputMask({
      mask: '999.9999.9999.99',
      placeholder: 'nnn.nnnn.nnnn.nn',
      clearMaskOnLostFocus: false
    }).mask(this.inputTarget)
  }
}
