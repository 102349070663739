import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="redirect"
export default class extends Controller {
  static values = { url: String }
  connect () {
    console.log('message-controller connect')
  }

  close (evt) {
    this.target.classList.add('hidden')
  }
}
