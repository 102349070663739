import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

// Connects to data-controller="context-menu"
export default class extends Controller {
  static values = {
    url: { type: String, required: false },
    listener: { type: String, required: false }
  }
  static targets = [ 'menu' ]

  connect () {
    console.log('context-menu', this.urlValue, this.listenerValue, this.menuTarget)
    useClickOutside(this)

    if (this.listenerValue) {
      // const ce = new CustomEvent(this.listenerValue)
      // window.dispatchEvent(ce)
      window.addEventListener(this.listenerValue, this.set_params.bind(this), false)
    }

    try {
      this.id = JSON.parse(this.element.dataset.id)
    } catch (e) {
      // console.log(e)
    }
    this.token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content
  }

  disconnect () {
    window.removeEventListener(this.listenerValue, this.set_params, false)
  }

  set_params (evt) {
    console.log('context_menu#set_params', evt, this.menuTarget.getBoundingClientRect())
    const corr = this.menuTarget.getBoundingClientRect()
    if (!evt.detail) return

    this.menuTarget.style.left = (evt.detail.x - corr.width) + 'px'
    this.menuTarget.style.top = (evt.detail.y) + 'px'
  }

  async toggle (e) {
    console.log('toggle', this.menuTarget, e.params)
    if (e.target.closest('a')) return

    if (e.params.url) {
      await this.fetch(e.params.url)
    }

    this.menuTarget.style.left = (e.layerX - 50) + 'px'
    this.menuTarget.style.top = (e.layerY - 30) + 'px'

    setTimeout(() => {
      this.show()
    }, 50)
  }

  show (evt) {
    console.log('context-menu#show', evt)
    this.menuTarget.classList.remove('hidden', 'opacity-0')
    this.menuTarget.classList.add('opacity-100')
  }

  hide (evt) {
    this.menuTarget.classList.add('hidden', 'opacity-0')
    this.menuTarget.classList.remove('opacity-100')
  }

  async fetch (url) {
    await fetch(`${ url }`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.token,
        Accept: "text/vnd.turbo-stream.html",
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
    }).then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
  }
}


