import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toast"
export default class extends Controller {
  connect () {
    console.log('toast controller connect')
  }

  close (evt) {
    this.element.classList.add('hidden')
    this.element.remove()
  }
}


