// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import { Turbo } from "@hotwired/turbo-rails"

import "../controllers"
import "../channels"

// import { Issue } from '../channels/issue_channel'

Turbo.start()

import { far } from "@fortawesome/free-regular-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-free"

library.add(far, fas, fab)

import 'trix'
import "@rails/actiontext"

import 'chartkick/chart.js'

document.addEventListener('turbo:load', () => {
  console.log('loading and listeing in application')
  // const issue = new Issue(1)

  // setInterval(() => {
  //   try {
  //     window.App.ping.i_am_alive()
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }, 10000)
})