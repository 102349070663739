import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static values = {
    className: String
  }
  static targets = [ 'toggleable', 'sidebar' ]

  connect () {
    console.log('toggle controller connect')
  }

  toggle () {
    console.log('toggle clicked',
      this.classNameValue,
      this.toggleableTargets
    )

    let t = this.classNameValue ? this.classNameValue : 'invisible'
    // this.toggleableTarget.classList.toggle(t)
    this.toggleableTargets.forEach((e) => {
      e.classList.toggle(t)
    })
  }

  sidebar () {
    console.log('sidebar')
    this.sidebarTargets.forEach((e) => {
      e.classList.toggle("minimized")
    })
  }

  close () {
    this.toggleableTarget.classList.add("invisible")
  }
}
