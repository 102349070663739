import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal-window"
export default class extends Controller {
  static targets = [ 'window' ]

  connect () {
    console.log('modal-window controller connect')
  }

  hide (evt) {
    this.windowTarget.classList.add('hidden')
  }
}
