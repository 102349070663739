import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = [ 'buttons', 'customButtons', 'overviewButton',
    'issueOverview', 'issuesContainer', 'employerId' ]

  connect () {
    console.log('tabs controller connect')

    this.current_buttons = this.customButtonsTargets

    this.loop_id = setInterval(() => {
      // console.log('tabs#connect', this.loop_id, new Date())
      // console.log(this.current_buttons)

      let childs = Array.from(this.customButtonsTarget.children)?.map((e) => e.dataset.randomId)
      let len = childs.length

      if (JSON.stringify(this.current_buttons) !== JSON.stringify(childs) && childs.length > 0) {
        // console.log('diff found, tab repaint', childs)
        this.hide_all()

        this.show(childs[ len - 1 ])
        this.active(childs[ len - 1 ])
      }
      this.current_buttons = childs

    }, 500)
  }

  disconnect () {
    clearInterval(this.loop_id)
  }

  hide (elem) {
    elem.classList.remove('opacity-100')
    elem.classList.add('hidden', 'opacity-0')
  }

  show (id) {
    let container = document.getElementById(`issue-edit-${ id }`)
    console.log('show', container)
    container.classList.remove('hidden', 'opacity-0')
    container.classList.add('opacity-100')
  }

  inactive (elem) {
    elem.classList.remove('active')
  }

  appendEmployer (evt) {
    try {
      this.employerIdTarget
    } catch (error) {
      evt.preventDefault()
      alert('In dieser Dienstabteilung kann kein neuer Fall erstellt werden.')
    }

    let url = new URL(evt.target.closest('a').href)
    url.searchParams.set('employer_id', this.employerIdTarget?.value)

    console.log('appendEmployer', url, this.employerIdTarget, url)

    evt.target.closest('a').href = url
  }

  active (id) {
    let button = document.getElementById(`issue-button-${ id }`)
    console.log('active', button)
    button.classList.add('active')
    button.classList.remove('hidden')
  }

  // remove button and div container
  remove (elem_id) {
    document.getElementById(`issue-button-${ elem_id }`)?.remove()
    document.getElementById(`issue-edit-${ elem_id }`)?.remove()
  }

  close (evt) {
    const el = evt.target.closest('a')
    let res = true
    if (el.dataset.turboConfirm > '') {
      res = confirm(el.dataset.turboConfirm)
    }
    if (!res) { return }
    this.remove(el.dataset.randomId)
    this.show_overview()
  }

  show_overview (evt) {
    this.hide_all()

    this.active('overview')
    this.show('overview')
  }

  activate (evt) {
    this.hide_all()

    this.active(evt.target.dataset.randomId)
    this.show(evt.target.dataset.randomId)
  }

  hide_all () {
    let childs = Array.from(this.customButtonsTarget.children)

    this.hide(this.issueOverviewTarget)
    this.inactive(this.overviewButtonTarget)

    childs.forEach(c => this.inactive(c))

    childs = Array.from(this.issuesContainerTarget.children)
    childs.forEach(c => this.hide(c))
  }
}
