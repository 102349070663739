import { Controller } from "@hotwired/stimulus"
import { Issue } from "../channels/issue_channel"

// Connects to data-controller="issue-form"
export default class extends Controller {
  static targets = [
    "form",
    "block",
    "menu",
    "toggleMenu",
    "buttonPrev",
    "buttonNext",
    "displayMode",
    "currentTab",
    "idField",
    "employers",
    "damages",
    "socials",
    "employerId",

    // base salery fields
    "saleryRandom",
    "saleryTypeField",
    "baseSaleryTotal",
    "baseSaleryField",
    "gratificationField",
    "childBenefitField",
    "additionalBenefitField",

    // hourly salery data
    "saleryDateField",
  ];

  connect () {
    console.log("issue-form#connect")
    // console.log('issue-form#connect', this.formTarget, this.blockTargets)

    // store current version form data and compare is later
    // with before send
    // this.orig_form_data = new FormData(this.formTarget)

    // this is a channel to the server
    // this.issue = new Issue(this.idFieldTarget.value)
    // this.issue.changed_data('in connect')

    // show all data at once or in blocks
  }

  disconnect () {
    this?.issue?.unsubscribe()
  }

  formTargetConnected () {
    this.orig_form_data = new FormData(this.formTarget)
    this.issue = new Issue(this.idFieldTarget.value)

    if (this.displayModeTarget?.value && this.currentTabTarget?.value) {
      this.change_visible_block_to(this.currentTabTarget.value)
    }

    this.find_and_display_errors()
  }

  focus (evt) {
    console.log("issue-form#focus", evt)
  }

  send (evt) {
    console.log("issue-form#send", new FormData(this.formTarget).get("id"))
    const current_form_data = new FormData(this.formTarget)

    // compare them
    let differences = []
    for (let [ k, v ] of current_form_data.entries()) {
      if (this.orig_form_data.get(k) !== v) {
        differences.push({ [ k ]: v })
      }
    }

    // compare salery data
    // if type is hourly data, then find differences
    // in salery data

    try {
      console.log("issue-form#send, diff found", differences)
      if (differences && differences.length > 0) {
        // differences.push({ 'id': current_form_data.get('id') })
        differences.push({ id: this.idFieldTarget.value })
        differences.push({ random_id: current_form_data.get("random_id") })
        differences.push({
          display_mode: current_form_data.get("display_mode"),
        })
        differences.push({
          current_block: current_form_data.get("current_block"),
        })

        this.issue = new Issue(this.idFieldTarget.value)
        this.issue.send_changed_data({ data: differences })
        this.orig_form_data = current_form_data
        // this.formTarget.submit()
      } else {
        evt.preventDefault()
      }
    } catch (error) {
      console.log(error)
    }
  }

  prev (evt) {
    let block = this.current_block()
    let idx = this.index_block(block)
    let len = this.count_blocks()
    let new_pos = 0

    evt.preventDefault()
    console.log("prev", this.buttonPrevTarget)

    if (idx - 1 >= 0) {
      new_pos = idx - 1
    } else {
      new_pos = len - 1
    }

    this.currentTabTarget.value = new_pos
    this.change_visible_block_to(new_pos)
  }

  next (evt) {
    let block = this.current_block()
    let idx = this.index_block(block)
    let len = this.count_blocks()
    let new_pos = 0

    evt.preventDefault()
    console.log("next", this.buttonNextTarget)
    console.log(len, idx, block)

    if (idx + 1 < len) {
      new_pos = idx + 1
    } else {
      new_pos = 0
    }

    this.currentTabTarget.value = new_pos
    this.change_visible_block_to(new_pos)
  }

  toggleDisplay (evt) {
    evt.preventDefault()

    if (
      this.displayModeTarget?.value &&
      this.displayModeTarget.value == "block"
    ) {
      this.displayModeTarget.value = "all"
      this.show_all_blocks()
      this.menuTarget.classList.add("hidden")
      this.toggleMenuTarget.innerHTML =
        "<i class='fa fa-eye-slash'></i> Block anzeigen"
    } else {
      this.displayModeTarget.value = "block"
      this.hide_all_blocks()
      this.change_visible_block_to(0)
      this.menuTarget.classList.remove("hidden")
      this.toggleMenuTarget.innerHTML = "<i class='fa fa-eye'></i> Alle Daten"
    }
  }

  switch_to (evt) {
    evt.preventDefault()

    const name = evt.params.target
    const block = this.find_block(name)
    const idx = this.index_block(block)

    console.log("index of found block", idx)
    this.currentTabTarget.value = idx
    this.change_visible_block_to(idx)
  }

  hide_all_blocks () {
    this.blockTargets.forEach((b) => b.classList.add("hidden"))
    this.buttonPrevTarget.classList.remove("hidden")
    this.buttonNextTarget.classList.remove("hidden")
  }

  show_all_blocks () {
    this.blockTargets.forEach((b) => b.classList.remove("hidden"))
    this.buttonPrevTarget.classList.add("hidden")
    this.buttonNextTarget.classList.add("hidden")
  }

  change_visible_block_to (idx) {
    this.blockTargets.forEach((b) => b.classList.add("hidden"))
    this.blockTargets[ idx ].classList.remove("hidden")
  }

  find_block (name) {
    return this.blockTargets.find((b) => {
      console.log("find block by name", name, b.classList)
      return b.classList.contains(name)
    })
  }

  current_block () {
    return this.blockTargets.find((b) => !b.classList.contains("hidden"))
  }

  index_block (block) {
    return this.blockTargets.indexOf(block)
  }

  count_blocks () {
    return this.blockTargets.length
  }

  find_and_display_errors () {
    // this.querySelectorAll('.issue-injured div.field_with_errors > label')
    const form_blocks = this.menuTarget.querySelectorAll("li a")
    // console.log('issue-form#connect', form_blocks)
    // console.log('---------------------------------------------------------')
    form_blocks.forEach((b) => {
      let cnt = this.formTarget.querySelectorAll(
        `.${ b.dataset.issueFormTargetParam } div.field_with_errors input, .${ b.dataset.issueFormTargetParam } div.field_with_errors select, .${ b.dataset.issueFormTargetParam } div.field_with_errors textarea`,
      )?.length
      // console.log(b.dataset, cnt)
      if (cnt > 0) {
        this.formTarget.querySelector(
          `.${ b.dataset.issueFormTargetParam }-error-cnt`,
        ).innerHTML = cnt
        this.formTarget
          .querySelector(`.${ b.dataset.issueFormTargetParam }-error-cnt`)
          ?.classList?.remove("hidden")
      } else {
        this.formTarget
          .querySelector(`.${ b.dataset.issueFormTargetParam }-error-cnt`)
          ?.classList?.add("hidden")
      }
    })
  }

  saleryRandomTargetConnected () {
    console.log(
      "Lohndaten wurden neu geladen ...",
      this.saleryRandomTarget.value,
    )
    this.find_and_display_errors()
  }

  // observer for base salery data
  baseSaleryTotalTargetConnected () {
    console.log("lohndaten wurden nun geladen und werden berechnet")
    this.recalc_base_salery()
  }

  recalc_base_salery (evt) {
    let sum = 0.0
    const fields = [
      "baseSaleryField",
      "gratificationField",
      "childBenefitField",
      "additionalBenefitField",
    ]

    fields.forEach((f) => {
      let t = parseFloat(this[ `${ f }Target` ].value)
      if (t) sum += t
    })
    this.baseSaleryTotalTarget.value = new Intl.NumberFormat("de-CH")
      .format(sum)
      .toString()
  }

  changeSalery (evt) {
    let button = evt.target
      .closest("div")
      .querySelectorAll("a")
      .forEach((e) => {
        e.classList.remove("bg-sky-600/80", "text-white")
      })

    evt.target.classList.add("bg-sky-600/80", "text-white")

    console.log(
      "saleryfields",
      this.saleryTypeFieldTarget.value,
      evt.params.saleryType,
      evt.params.saleryTypeId,
    )
    this.saleryTypeFieldTarget.value = evt.params.saleryTypeId
  }

  //
  //
  //
  parseDate (input) {
    const parts = input.match(/(\d+)/g)
    try {
      return new Date(parts[ 2 ], parts[ 1 ] - 1, parts[ 0 ])
    } catch (e) {
      return null
    }
  }

  check_salery_date_field (evt) {
    console.log("issue-form#check_salery_date_field", evt.params.cnt)
    if (evt.params.cnt != 1) return

    // console.log('date_fields', this.saleryDateFieldTargets, this.saleryDateFieldTarget)

    const date_field = this.saleryDateFieldTarget.value
    let date = this.parseDate(date_field)

    if (!date) return

    try {
      console.log("date", this.saleryDateFieldTarget.value)
      console.log("parsed date", date.toLocaleString())

      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()
      let dow = date.getDay()

      // 0 = So, 1 = mo, 2 = di, 3 = mi, 4 = do, 5 = fr, 6 = sa
      const sub_days = [ 7, 0, 1, 2, 3, 4, 5 ]

      date.setDate(day - sub_days[ date.getDay() ])

      if (Date.parse(date.toDateString()) && date_field.length > 4) {
        // this.saleryDateFieldTargets[ 1 ].value = `${ date.getDate() }.${ date.getMonth() + 1 }.${ date.getFullYear() }`
        for (let i = 0;i < 12;i++) {
          this.saleryDateFieldTargets[ i ].value =
            `${ date.getDate() }.${ date.getMonth() + 1 }.${ date.getFullYear() }`
          date.setDate(date.getDate() - 7)
        }
      }
    } catch (error) {
      console.log(
        "given date is wrong",
        error,
        this.saleryDateFieldTarget.value,
      )
    }
  }

  showBlock (evt) {
    evt.preventDefault()
    console.log(
      evt.params,
      this.employersTargets,
      this[ `${ evt.params[ "block" ] }Targets` ],
    )
    let cnt = 0
    this[ `${ evt.params[ "block" ] }Targets` ].forEach((e) => {
      if (e.classList.contains("hidden")) {
        cnt++
        if (cnt <= 1) e.classList.remove("hidden")
      }
    })
  }
}
