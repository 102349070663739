import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toast"
export default class extends Controller {
  static targets = [ 'content', 'error' ]
  static values = { url: { type: String, required: true } }

  connect () {
    console.log('form-help loaded')
    this.token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content

    this.cache = []
  }

  show_error (content) {
    if (content?.length <= 5) this.errorTarget.parentElement.closest('div').classList.add('hidden')
    else this.errorTarget.parentElement.closest('div').classList.remove('hidden')

    this.errorTarget.innerHTML = content
  }

  async show (evt) {
    console.log('form-help#show', evt.params)

    let value = ''
    let found = null
    let error = evt.params.error

    this.show_error(error)

    if (found = this.cache.find(c => c.table == evt.params.table && c.field == evt.params.field)) {
      value = found.content
    } else {
      value = await this.get(evt.params.table, evt.params.field)
      this.cache.push({ table: evt.params.table, field: evt.params.field, content: value })
    }

    console.log(this.contentTarget.parentElement.closest('div'))
    this.contentTarget.innerHTML = value

    if (value.length <= 5) this.contentTarget.parentElement.closest('div').classList.add('hidden')
    else this.contentTarget.parentElement.closest('div').classList.remove('hidden')
  }

  close (evt) {
    this.element.classList.add('hidden')
    this.element.remove()
  }

  async get (table, field) {
    console.log(`form-help#fetch: ${ table } - ${ field }`)

    const form = new FormData()
    form.append('table', table)
    form.append('field', field)

    const value = await fetch(this.urlValue, {
      method: 'post',
      headers: {
        'X-CSRF-Token': this.token,
        // Accept: 'text/vnd.turbo-stream.html',
        // 'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: form
    }).then(response => response.text())
      .then(html => {
        // Turbo.renderStreamMessage(html)
        console.log('form-help#fetch', html)
        return html
      })
    return value
  }
}


