import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table-sort"
export default class extends Controller {
  static values = { url: String, employerId: Number }
  static targets = [ 'sortHeader', 'search', 'dialog', 'filter' ]

  connect () {
    console.log('table-sort', this.urlValue)

    this.token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content
  }

  search (evt) {
    console.log('search', evt, this.searchTarget.value, this.urlValue)
    this.fetch()
  }

  set_filter (evt) {
    console.log('table-sort#set_filter', this.filterData)

    // is element selected?
    const selected = evt.target.classList.contains('bg-stone-600')
    // find parent container
    const parent = evt.target.closest('.fields')

    //
    // clear all previous selections
    // but only if there is only one selection allowed
    //
    // Array.from(parent.querySelectorAll('div.item')).forEach((e) => {
    //   e.classList.remove('bg-stone-600', 'text-white')
    // })

    this.filterData ||= {}

    // set or clear clicked element
    if (!selected) {
      evt.target.closest('.item').classList.add('bg-stone-600', 'text-white')
      this.filterData[ evt.params.filter ] ||= []
      this.filterData[ evt.params.filter ].push(evt.params.value)
    } else {
      evt.target.closest('.item').classList.remove('bg-stone-600', 'text-white')
      this.filterData[ evt.params.filter ].splice(
        this.filterData[ evt.params.filter ].indexOf(evt.params.value),
        1)
    }
  }

  clearFilter () {
    this.filterData = {}
    this.filterTarget.querySelectorAll('.fields').forEach((fields) => {
      console.log(fields)
      Array.from(fields.children).forEach((c) => c.classList.remove('bg-stone-600', 'text-white'))
    })
  }

  sort (evt) {
    console.log('sort#click', evt.target.dataset)

    const fld = evt.target
    if (fld.dataset.tableSortColumnParam <= '') return

    fld.classList.remove('up', 'down')
    switch (fld.dataset.sort) {
      case '': fld.dataset.sort = 'up'; break
      case 'up': fld.dataset.sort = 'down'; break
      case 'down': fld.dataset.sort = ''; break
    }
    this.sortTable()
  }

  pageing ({ params }) {
    console.log('page', params.page)
    this.page = params.page

    this.fetch()
  }

  sortTable () {
    console.log('table-sort: #sortTable')
    this.sortFields = []

    // console.log(this.sortHeaderTarget.querySelectorAll('div.table-cell'))
    Array.from(this.sortHeaderTarget.children).forEach(element => {
      console.log(element.dataset)
      if (element.dataset.tableSortColumnParam > '' && element.dataset.sort > '') {
        this.sortFields.push({
          field_name: element.dataset.tableSortColumnParam,
          sort_direction: element.dataset.sort
        })
      }
    })
    // console.log('#sortTable sortFields:', this.sortFields)
    this.fetch()
  }

  async fetch () {
    console.log('before fetch', JSON.stringify(this.sortFields), 'employer_id', this.employerIdValue)
    await fetch(this.urlValue, {
      method: 'post',
      headers: {
        'X-CSRF-Token': this.token,
        Accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        page: this.page,
        sort_fields: this.sortFields,
        filter_fields: this.filterData,
        search: this.searchTarget.value,
        employer_id: this.employerIdValue
      })
    }).then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
  }
}
