import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input/intlTelInputWithUtils'
import de from "intl-tel-input/i18n/de"

// Connects to data-controller="phone-number"
export default class extends Controller {
  static targets = [ 'input' ]
  static values = {
    currentNumber: String,
    id: String
  }

  initialize () {
    // const elem = document.querySelector(`#${ this.idValue }`)
    console.log('phone-number connected', this.element, this.idValue)

    this.inputField = intlTelInput(this.element, {
      preferredCountries: [ 'ch', 'li', 'at', 'de', 'it', 'fr' ],
      separateDialCode: false,
      containerClass: 'w-full',
      // utilsScript: "intl-tel-input/utils.js",
      i18n: de,
      nationalMode: false
    })

    // preferredCountries: [ 'ch', 'li', 'at', 'de', 'it', 'fr' ],
    // utilsScript: 'utils.js',
    // localizedCountries
    // })

    // if (this.currentNumberValue !== '') {
    //   this.inputField.setNumber(this.currentNumberValue)
    // }
  }

  // disconnect () {
  //   this.inputField.destroy()
  //   super.disconnect()
  // }
}
