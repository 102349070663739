import consumer from "./consumer"

document.addEventListener('turbo:load', () => {
  if (window.App.relapse) return

  const login_session_id = document.querySelector('meta[name="current-session"]').content
  window.App.relapse = consumer.subscriptions.create({
    channel: 'RelapseChannel',
    login_session_id: login_session_id
  }, {
    connected () {
      console.log('>>>>>> relapse_channel connected', login_session_id)
    },

    disconnected () {
      console.log('relapse_channel disconnected', login_session_id)
    },

    received (response) {
      console.log('relapse_channel got response:', response)
    }
  })

})
