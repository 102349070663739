import consumer from "./consumer"

document.addEventListener('turbo:load', () => {
  if (window.App.message) return

  const login_session_id = document.querySelector('meta[name="current-session"]').content
  window.App.message = consumer.subscriptions.create({
    channel: 'MessageChannel',
    login_session_id: login_session_id
  }, {
    connected () {
      console.log('++++++ message_channel connected', login_session_id)
    },

    disconnected () {
      console.log('------ message_channel disconnected', login_session_id)
    },

    received (response) {
      console.log('message_channel got response:', response)
      switch (response.type) {
        case 'toast':
          document.getElementById('toast-container')
            .insertAdjacentHTML('afterbegin', response.content)
      }
    }
  })

})
