import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toast"
export default class extends Controller {
  static outlets = [ 'issue-form' ]
  static targets = [ "container", "content", 'seed' ]
  static values = { url: { type: String, required: true } }

  connect () {
    console.log("interact-warning loaded")
    this.token = document.querySelector('meta[name="csrf-token"]').content

    this.cache = []
  }

  async show (evt) {
    console.log("interact-warning#show", evt.params)
    this.containerTarget.classList.remove("hidden")

    // this.random_id = evt.params.randomid
    this.table = evt.params.table
    this.field = evt.params.field
    this.rule_id = evt.params.ruleid
    this.id = evt.params.id

    this.button = evt.target

    this.contentTarget.innerHTML = evt.params.message
  }

  close (evt) {
    evt.preventDefault()
    this.containerTarget.classList.add("hidden")
  }

  confirm (evt) {
    evt.preventDefault()
    this.send()
  }

  close_and_clean () {
    this.random_id = this.rule_id = this.table = this.field = this.id = this.button = null
    this.containerTarget.classList.add("hidden")
  }

  async send () {
    console.log(
      `form-help#send: ${ this.urlValue } - ${ this.table } - ${ this.field } - ${ this.id }`,
    )

    const form = new FormData()
    form.append("random_id", this.random_id)
    form.append("table", this.table)
    form.append("field", this.field)
    form.append("rule_id", this.rule_id)
    form.append("id", this.id)

    const value = await fetch(this.urlValue, {
      method: "post",
      headers: {
        "X-CSRF-Token": this.token,
        // 'Content-Type': 'application/json'
        // Accept: 'text/vnd.turbo-stream.html',
      },
      credentials: "same-origin",
      body: form,
    })
      .then((response) => response.text())
      .then((html) => {
        // Turbo.renderStreamMessage(html)
        console.log("form-help#fetch", html)
        // this.close_and_clean()
        // return html

        this.seedTarget.value = Math.random()
        // this.issueFormOutlet.send()
        this.button.closest('span').remove()
      })
    return value
  }
}
