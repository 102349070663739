import { Controller } from "@hotwired/stimulus"
import { createApp } from "vue"
import CanvasJSChart from '@canvasjs/vue-charts'

import App from '../../chart/App.vue'

// Connects to data-controller="chart-loader"
export default class extends Controller {
  connect () {
    console.log('chart stimulus loader')
    const params = this.element.dataset
    createApp(App, { ...params })
      .use(CanvasJSChart)
      .mount(`#${ this.element.id }`)
  }
}
