import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="codefield"
export default class extends Controller {
  connect () {
    console.log('>>>> codefield controller connect', this.element)
    this.element.addEventListener('keydown', this.handleKey)
  }

  disconnect () {
    this.element.removeEventListener('keydown', this.handleKey)
  }

  handleKey (evt) {
    if (evt.key === 'Tab') {
      evt.preventDefault()
      const cursorPosition = evt.target.selectionStart
      evt.target.value = evt.target.value.substring(0, cursorPosition) + '\t' + evt.target.value.substring(cursorPosition)
    }
  }
}
