import { Controller } from "@hotwired/stimulus"
import { useIdle } from 'stimulus-use'

// Connects to data-controller="field-sv-number"
export default class extends Controller {
  connect () {
    console.log('user-idle controller connect')
    useIdle(this)
  }

  away (event) {
    console.log('user is away ...')
  }

  back (event) {
    console.log('user is back ...')
  }
}