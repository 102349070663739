import consumer from "./consumer"

document.addEventListener('turbo:load', () => {
  if (window.App.ping) return

  const login_session_id = document.querySelector('meta[name="current-session"]').content
  window.App.ping = consumer.subscriptions.create({
    channel: 'PingChannel',
    login_session_id: login_session_id
  }, {
    connected () {
      console.log('>>>>>> ping_channel connected', login_session_id)
    },

    disconnected () {
      console.log('ping_channel disconnected', login_session_id)
    },

    received (response) {
      console.log('ping_channel got response:', response)
    },

    i_am_alive: function () {
      console.log('ping_channel i_am_alive')
      return this.perform('i_am_alive')
    },

    check_for_new_message () {
      // console.log('check for message')
      return this.perform("check_for_new_message", 1)
    }
  })

})
