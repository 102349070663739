import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-doctor"
export default class extends Controller {
  static targets = [ 'firstCare', 'nextCare', 'window' ]

  connect () {
    console.log('select-doctor controller connect')
  }

  open (evt) {
    // evt.preventDefault()
    console.log('select-doctor controller open')
    this.fillField = evt.params.addressField

    // this.loginFormTarget.classList.add('hidden')
    this.windowTarget.showModal()
    // this.windowTarget.classList.remove('hidden')
  }

  close (evt) {
    // evt.preventDefault()
    console.log('select-doctor controller close')
    this.windowTarget.close()
  }

  fill (evt) {
    console.log('fill', this[ `${ this.fillField }Target` ], this.fillField, this.firstCareTarget, evt.params.address)
    this[ `${ this.fillField }Target` ].value = evt.params.address
    this.close()
  }
}
