import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="zipcode-city"
export default class extends Controller {
  static values = { url: String }
  static targets = [ 'zipField', 'cityField' ]

  connect () {
    console.log('zipcode-city connect')
    this.orig_value = this.zipFieldTarget.value
  }

  get (evt) {
    const search = evt.target.value

    if (this.search == this.zipFieldTarget.value) return

    console.log('get from', this.urlValue, 'with search', search)

    fetch(`${ this.urlValue }?search=${ search }`, {
      method: 'get',
      headers: {
        'Content-type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('got data from search', data)
        this.cityFieldTarget.value = data[ 0 ].text
      })
  }
}
