import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="monitor-url"
export default class extends Controller {
  static values = {
    url: String,
    newIssue: String
  }

  connect () {
    console.log('monitor-url controller connect', this.newIssueValue)
    let data = { open: true }

    const url = new URL(this.newIssueValue)
    const curr = new URL(window.location.href)

    const searchParams = new URLSearchParams(url.search)

    if (
      url.origin == curr.origin &&
      url.pathname == curr.pathname &&
      url.search == curr.search &&
      searchParams.get('mode') == 'new'
    ) {
      // setTimeout(() => {
      document.getElementById('issue-new-link')
        .dispatchEvent(new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        }))
      // }, 250)
    }
  }
}
