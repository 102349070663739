import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customer-events"
export default class extends Controller {
  connect () {
    console.log('customer-events connected')
  }

  send (evt) {
    console.log('customer-events#send', evt)

    const name = evt.params.name
    const data = evt.params.data

    window.dispatchEvent(new CustomEvent(name, {
      detail: { ...data },
      bubble: true
    }))
  }

  info (evt) {
    console.log('customer-events receiver', evt.params)
  }
}
