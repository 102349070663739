import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="redirect"
// #redirect
export default class extends Controller {
  static values = {
    url: String,
    immediately: String
  }

  connect () {
    console.log('redirect controller connect', Date.now())
    if (this.immediatelyValue > '') {
      window.location.href = this.urlValue
    }
  }

  doit (evt) {
    evt.preventDefault()

    console.log('redirect controller #redirect', evt.target.href)
    window.location.href = this.urlValue
  }

  checkAndNew (evt) {
    console.log('redirect controller#checkAndNew', evt.target.href, window.location.href)
    evt.preventDefault()

    const url = new URL(evt.target.href)
    const curr = new URL(window.location.href)

    const searchParams = new URLSearchParams(url.search)

    if (
      url.origin !== curr.origin ||
      url.pathname !== curr.pathname ||
      url.search !== curr.search
    ) {
      window.location.href = evt.target.href
    } else {
      if (searchParams.get('mode') !== 'new') return

      document.getElementById('issue-new-link')
        .dispatchEvent(new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        }))
    }
  }
}
